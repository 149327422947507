import React, { useEffect, useState } from "react";
import { useBreakpoints } from "../../../../hooks";

// Adding 0 as an option ignores the formatting for that screen size
const OrphanEliminator = ({ children, numberOfLastWords }) => {
  let childrenElements = React.Children.toArray(children);
  if (childrenElements.length > 1) {
    throw Error("Can't have more than one child");
  }
  const currElement = childrenElements[0];
  const [mobileOrphans, tabletOrphans, desktopOrphans] = numberOfLastWords;
  const [currentText, setCurrentText] = useState(
    childrenElements[0]?.props?.children
  );
  const bp = useBreakpoints();

  useEffect(() => {
    let splitText = currElement?.props?.children
      .trim()
      .replace(/&nbsp;/g, " ")
      .split(/ (?=[^>]*(?:<|$))/);
    /*
        Algorithm
        While the orphanCount > 1,
        add a nbsp; to the last word of the array
        combine the last two words
        slice a new array and set the last item as the combine
        decrement by 1
    */
    const bpToOrphan = (bp) => {
      switch (bp) {
        case "desktop":
          return desktopOrphans;
        case "largeTablet":
          return tabletOrphans;
        case "tablet":
          return tabletOrphans;
        default:
          return mobileOrphans;
      }
    };
    let orphanCount = bpToOrphan(bp);
    while (orphanCount > 0 && splitText.length > 2) {
      const lastWord = splitText[splitText.length - 1];
      let appendedWords;
      if (orphanCount === 1) {
        appendedWords = `<br/>${lastWord}`;
        splitText = splitText.slice(0, splitText?.length - 1);
        splitText[splitText.length] = appendedWords.split("<br/>").join("\n");
      } else {
        appendedWords = splitText[splitText.length - 2] + "\u00a0" + lastWord;
        splitText = splitText.slice(0, splitText?.length - 2);
        splitText[splitText.length] = appendedWords;
      }
      orphanCount -= 1;
    }
    setCurrentText(splitText.join(" "));
  }, [bp, mobileOrphans, tabletOrphans, desktopOrphans, currElement]);

  //ignore class
  return String(currElement?.props?.class).includes("ignore-orphan")
    ? currElement
    : React.cloneElement(
        currElement,
        // preserve newlines and spaces within an element
        {
          className: `${currElement?.props?.className} whitespace-pre-wrap`,
        },
        currentText
      );
};

export default OrphanEliminator;
