import React from "react";
import { Image } from "../../global/gui/image";

const languages = [
  "JavaScript",
  "Typescript",
  "React.js",
  "Node.js",
  "Redux",
  "React Query",
  "Next.js",
  "React Native",
  "Gatsby",
  "HTML",
  "CSS",
  "AWS",
  "XState",
];
const devTools = [
  "Git",
  "API Design",
  "UI/UX",
  "GraphQL",
  "Express",
  "MongoDB",
  "DynamoDB",
  "Scrum",
  "Agile",
  "ServiceNow",
];

const Technologies = () => (
  <div className="bg-white">
    <div className="space-y-8 md:space-y-16 container xl:max-w-5xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
      <div className="text-center">
        <div className="text-sm uppercase font-bold tracking-wider mb-1 text-blue-700">
          Languages & Dev Tools
        </div>
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
          Languages I Speak
        </h2>
        <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 lg:w-2/3 mx-auto">
          A list of the programming languages and development tools I use. This
          list is ever changing as I learn and adapt.
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12">
        <div className="sm:flex sm:space-x-10 py-5">
          <div className="hidden md:inline-flex flex-none group items-center justify-center w-12 h-12 ml-3 mb-8 sm:mb-0 sm:mt-5 relative">
            <div className="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-blue-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
            <div className="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-blue-800 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>
            <svg
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-code inline-block w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
              ></path>
            </svg>
          </div>
          <div>
            <h4 className="text-lg font-bold mb-2">
              <svg
                className="hi-solid hi-code inline-block w-6 h-6 md:hidden text-blue-500 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Languages
            </h4>
            <p className="leading-relaxed text-gray-600 pb-4">
              Frontend, backend, & everything in between
            </p>
            {languages.map((language, index) => (
              <div
                className="flex items-center space-x-3"
                key={`language-${index}`}
              >
                <div className="flex-none flex items-center justify-center">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="hi-solid hi-check-circle inline-block w-5 h-5 text-green-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h4 className="font-semibold">{language}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className="sm:flex sm:space-x-10 py-5">
          <div className="hidden md:inline-flex flex-none groupitems-center justify-center w-12 h-12 ml-3 mb-8 sm:mb-0 sm:mt-5 relative">
            <div className="absolute inset-0 rounded-xl -m-3 transform -rotate-6 bg-blue-300 transition ease-out duration-150 group-hover:rotate-6 group-hover:scale-110"></div>
            <div className="absolute inset-0 rounded-xl -m-3 transform rotate-2 bg-blue-800 bg-opacity-75 shadow-inner transition ease-out duration-150 group-hover:-rotate-3 group-hover:scale-110"></div>
            <svg
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="text-white relative transform transition ease-out duration-150 opacity-90 group-hover:scale-125 group-hover:opacity-100 hi-outline hi-cog inline-block w-10 h-10"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              ></path>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
          </div>
          <div>
            <h4 className="text-lg font-bold mb-2">
              <svg
                className="hi-outline hi-cog inline-block w-6 h-6 md:hidden text-blue-500 mr-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              Tools & Frameworks
            </h4>
            <p className="leading-relaxed text-gray-600 pb-4">
              The tools & frameworks that enhance the developer experience
            </p>
            {devTools.map((tool, index) => (
              <div
                className="flex items-center space-x-3"
                key={`dev-tool-${index}`}
              >
                <div className="flex-none flex items-center justify-center">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="hi-solid hi-check-circle inline-block w-5 h-5 text-green-500"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <h4 className="font-semibold">{tool}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative mx-5 lg:mx-auto lg:w-7/12 -mb-5 sm:mb-0">
        <Image filename={"collaboration"} />
      </div>
    </div>
  </div>
);

export default Technologies;
