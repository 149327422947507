import React from "react";
import { Image } from "../../global/gui/image";

const projects = [
  {
    title: "Stealth Startup",
    image: "projects-stealth",
    link: "",
    githubLink: "",
    description: "Secretly working on a real estate platform for homeowners",
  },
  {
    title: "AnnMade",
    image: "projects-annmade",
    link: "https://www.annmadeshop.com/",
    githubLink: "",
    description:
      "Cofounder of AnnMade, a custom Shopify storefront & React application",
  },
  {
    title: "Identity Generator",
    image: "projects-identity-generator",
    link: "https://identity-generator-obj68m.stackblitz.io/",
    githubLink: "https://github.com/daniel-martin94/identity-generator",
    description: "React application to generate a new identity",
  },
  {
    title: "To Do List",
    image: "projects-to-do",
    link: "https://react-pure-to-do.stackblitz.io/",
    githubLink: "https://github.com/daniel-martin94/react-pure-to-do-list",
    description: "A simple React to do list",
  },
  {
    title: "Taco Shop",
    image: "projects-taco-order",
    link: "https://taco-order-form-fw7dpe.stackblitz.io/",
    githubLink: "https://github.com/daniel-martin94/taco-order-form-fw7dpe",
    description: "Simple and elegant order form for tacos in React",
  },
  {
    title: "Tic Tac Toe in React Native",
    image: "projects-tic-tac-toe",
    link: "https://snack.expo.io/@dpmartin/tic-tac-toe",
    githubLink:
      "https://github.com/daniel-martin94/react-native-tic-tac-toe/tree/master/tic-tac-toe",
    description: "1v1 Tic Tac Toe Game written in React Native",
  },
];

const Projects = () => (
  <div className="bg-white" id={"projects"}>
    <div className="space-y-12 container xl:max-w-7xl mx-auto px-4 pb-16 lg:px-8 lg:pb-32">
      <div className="border-b py-2 flex items-center justify-between">
        <h3 className="uppercase font-semibold tracking-wide text-xl">
          Projects
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {projects.map(({ title, image, link, githubLink, description }, index) => (
          <div className="flex flex-col" key={index}>
            {!!link ? (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="block relative group rounded overflow-hidden"
              >
                <div className="flex items-center justify-center absolute inset-0 bg-blue-700 bg-opacity-75 opacity-0 transition ease-out duration-150 group-hover:opacity-100">
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-white transform -rotate-45 hi-solid hi-arrow-right inline-block w-10 h-10"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div
                  className={`max-h-[10rem] flex flex-col items-center justify-center mx-auto`}
                >
                  <Image useImgTag filename={image} />
                </div>
              </a>
            ) : (
              <div className="block relative rounded overflow-hidden">
                <div
                  className={`max-h-[10rem] flex flex-col items-center justify-center mx-auto`}
                >
                  <Image useImgTag filename={image} />
                </div>
              </div>
            )}
            <div className="w-full">
              {!!link ? (
                <>
                  <h4 className="font-bold text-lg sm:text-xl mt-4 mb-2">
                    <a
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                      className="leading-7 text-gray-800 hover:text-gray-600"
                    >
                      {title}
                    </a>
                  </h4>
                  <p className="leading-relaxed text-gray-600 mb-2">
                    {description}
                  </p>
                </>
              ) : (
                <>
                  <h4 className="font-bold text-lg sm:text-xl mt-4 mb-2">
                    <div className="leading-7 text-gray-800">{title}</div>
                  </h4>
                  <p className="leading-relaxed text-gray-600 mb-2">
                    {description}
                  </p>
                </>
              )}
            </div>
            <div>
              {!!githubLink && (
                <button
                  type="button"
                  className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-2 py-1 leading-5 text-sm rounded border-gray-200 bg-gray-200 text-gray-700 hover:text-gray-700 hover:bg-gray-300 hover:border-gray-300 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-gray-200 active:border-gray-200"
                >
                  <svg
                    className="opacity-50 hi-solid hi-chat-alt-2 inline-block w-4 h-4"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                  <span>Source Code</span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Projects;
