import React from "react";
import { Link } from "gatsby";
import { Image } from "../../global/gui/image";
import Resume from "../../../assets/Resume.pdf";
import { OrphanEliminator } from "../../global/gui/text";

const Hero = () => (
  <div className="bg-white relative overflow-hidden">
    <div className="relative container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
      <div className="text-left md:text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4 md:w-3/4 lg:w-full mx-auto">
          Senior Software Engineer
        </h2>
        <OrphanEliminator numberOfLastWords={[0, 4, 0]}>
          <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 w-93-percent ml-0 md:mx-auto mx-auto">
            Building software through years of experience,
            knowledge, and collaboration.
          </h3>
        </OrphanEliminator>
      </div>
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16">
        <a
          href={Resume}
          className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-blue-500 bg-blue-500 text-white hover:text-white hover:bg-blue-600 hover:border-blue-600 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-blue-500 active:border-blue-500"
        >
          <svg
            className="opacity-50 hi-outline hi-document-download inline-block w-6 h-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
          <span>View Resume</span>
        </a>
        <Link
          to="/contact"
          className="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-gray-700 bg-gray-700 text-white hover:text-white hover:bg-gray-800 hover:border-gray-800 focus:ring focus:ring-gray-500 focus:ring-opacity-25 active:bg-gray-700 active:border-gray-700"
        >
          <span>Get In Touch</span>
        </Link>
      </div>
      <div className="relative mx-5 lg:mx-auto lg:w-7/12 -mb-5 sm:mb-0">
        <Image filename={"homepage-hero"} />
      </div>
    </div>
  </div>
);

export default Hero;
