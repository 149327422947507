import React from "react";
import { Image } from "../../global/gui/image";

const Companies = () => (
  <div className="bg-blue-500">
    <div className="container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
      <div className="text-center mb-12">
        <div className="text-md uppercase font-bold tracking-wider mb-1 text-white mx-8">
          Awesome Companies I've Teamed Up With
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 text-center text-gray-400">
        <div className="h-28 flex items-center justify-center bg-white">
          <div className="inline-flex items-center space-x-2 text-3xl font-bold">
            <div className="relative flex items-center justify-center mx-5 lg:mx-auto w-40 lg:w-7/12 lg:p-8">
              <Image filename={"emcee"} />
            </div>
          </div>
        </div>
        <div className="h-28 flex items-center justify-center bg-white">
          <div className="inline-flex items-center space-x-2 text-3xl font-bold">
            <div className="relative flex items-center justify-center mx-5 lg:mx-auto w-40 lg:w-7/12 lg:p-8">
              <Image filename={"figure"} />
            </div>
          </div>
        </div>
        <div className="h-28 flex items-center justify-center bg-white">
          <div className="relative flex items-center justify-center mx-5 lg:mx-auto w-52 lg:w-7/12 lg:p-8">
            <Image filename={"popsugar"} />
          </div>
        </div>
        <div className="h-28 flex items-center justify-center bg-white">
          <div className="relative flex items-center justify-center mx-5 lg:mx-auto w-52 lg:w-7/12 lg:p-8">
            <Image filename={"sfo"} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Companies;
