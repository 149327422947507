import { useEffect, useState } from 'react';

import { Detection, Width } from '../constants';

const useBreakpoints = (
  breakpoints = {
    tablet: Width.SCREEN_MD,
    largeTablet: Width.SCREEN_LG,
    desktop: Width.SCREEN_XL,
  }
) => {
  const [breakpoint, setBreakpoint] = useState('');
  const keys = Object.keys(breakpoints).reverse();

  useEffect(() => {
    /*
      After detection of the browser, declare two objects, where
        mediaQueries: cssMediaQuery => window.matchMedia(cssMediaQuery)
        matches: cssMediaQuery => breakpointKeys

      On initial render, we populate mediaQueries & matches, adding event listeners to each of the media queries.
        ex. mediaQueries[`(min-width: 840px)`] = window.matchMedia(`(min-width: 840px)`)
        ex. matches[`(min-width: 840px)`] = tablet

      The listeners then watch for matches based on the current event object and matches from the mediaQueries,
      where the breakpoint is now set to the the breakpoint key using the matches object.
        ex. matches[`(min-width: 840px)`] => tablet

      If there are no matches, then we need to remove the current eventListeners, re-add new eventListeners,
      and find the next suitable breakpoint

      Why this approach is better than window.resize:
      Lower average scripting and rendering times. This method uses Memoization to set a breakpoint
      from an object when we get a match of the media query change. The older method ran
      every time the window resized and ran a for loop to set a matching breakpoint.

    */
    if (Detection.IS_BROWSER) {
      let mediaQueries = {};
      let matches = {};

      const listener = e => {
        if (e && mediaQueries[e.media].matches) {
          setBreakpoint(matches[e.media]);
        } else {
          if (Object.keys(mediaQueries).length !== 0) {
            removeEventListeners();
          }
          addEventListeners();
        }
      };

      const removeEventListeners = () => {
        Object.keys(mediaQueries).forEach(key => {
          if (mediaQueries[key].removeEventListener) {
            mediaQueries[key].removeEventListener('change', listener);
          } else {
            mediaQueries[key].removeListener(listener);
          }
        });
      };

      const addEventListeners = () => {
        let bp = '';
        mediaQueries = {};
        matches = {};
        keys.forEach(key => {
          const query = `(min-width: ${breakpoints[key]}px)`;
          const mql = window.matchMedia(query);
          mediaQueries[query] = mql;
          matches[query] = key;

          mql.addEventListener ? mql.addEventListener('change', listener) : mql.addListener(listener);

          if (window.matchMedia(`(min-width: ${breakpoints[key]}px)`).matches && !bp) {
            bp = key;
          }
        });
        setBreakpoint(bp);
      };

      //Initialize addingEventListeners
      addEventListeners();

      return () => removeEventListeners;
    }
    //eslint-disable-next-line
  }, []);
  return breakpoint;
};

export default useBreakpoints;
