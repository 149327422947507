import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = ({ filename, useImgTag = false }) => (
  <StaticQuery
    query={graphql`
      {
        images: allFile {
          edges {
            node {
              relativePath
              name
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  formats: [WEBP]
                )
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n) => {
        return n.node.name === filename;
      });
      if (!image) {
        return null;
      }
      const { publicURL, childImageSharp, extension } = image.node;
      if (extension === "gif" || useImgTag) {
        return <img src={publicURL} alt={filename || ""} />;
      }
      let { gatsbyImageData } = childImageSharp;
      return <GatsbyImage image={gatsbyImageData} alt={filename || ""} />;
    }}
  />
);

export default Image;
