import * as React from "react";
import { PageLayout } from "../components/global/layouts";
import { SiteMetadata } from "../components/global/shared";
import {
  About,
  Companies,
  Hero,
  Technologies,
  Projects,
  Articles,
} from "../components/page/home";

const IndexPage = () => {
  return (
    <PageLayout>
      <SiteMetadata title="Home" description="Daniel Martin" />
      <Hero />
      <About />
      <Technologies />
      <Projects />
      <Companies />
      <Articles />
    </PageLayout>
  );
};

export default IndexPage;
