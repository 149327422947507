import React from "react";
import sfoCopilot from "../../../assets/sfoCopilotArticle.pdf";
import figureHeloc from "../../../assets/figure-heloc.pdf";
import { OrphanEliminator } from "../../global/gui/text";

const articles = [
  {
    title: "US Ignite Celebrates the Newest Leaders in Smart Cities",
    description:
      "As cities around the world rapidly urbanize, they will need to be more connected, more efficient, and “smarter” than ever before. And last month in San Francisco, City Innovate presented the US Ignite Awards at Bridge Summit Conference, celebrating some of the startups that are making that a reality.",
    tag: "Lifestyle",
    link: "https://medium.com/@CityInnovate/us-ignite-celebrates-the-newest-leaders-in-smart-cities-5ee6ef779daf",
  },
  {
    title: "SFO’s Copilot app gives employees a snapshot of airport operations",
    description:
      "Mobile app was developed in-house by SFO's Information Technology and Telecommunications (ITT) team, with the initial objective of providing up-to-date operational information to enable airport staff to better assist travelers.",
    tag: "Productivity",
    link: sfoCopilot,
  },
  {
    title: "Figure Launches HELOC Wholesale Model",
    description:
      "Figure's seamless digital wholesale model offers end-to-end support of the origination process – from broker application, to customer fulfillment, to funding. Originators will leverage Figure's online pre-application process to pre-qualify their customers, helping them assess the best option available to meet their customers' needs.",
    tag: "Finance",
    link: figureHeloc,
  },
];

const Articles = () => (
  <div className="bg-gray-100" id="articles">
    <div className="space-y-16 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32">
      <div className="text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4">
          In the Media
        </h2>
        <OrphanEliminator numberOfLastWords={[4, 9, 9]}>
          <h3 className="text-lg md:text-xl md:leading-relaxed font-medium text-gray-600 mx-auto">
            Articles about impactful past work.
          </h3>
        </OrphanEliminator>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-2">
        {articles.map(({ title, description, tag, link }, index) => (
          <div
            className="bg-white rounded-lg overflow-hidden shadow-sm w-full"
            key={index}
          >
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="block relative group rounded lg:rounded-none w-full"
            >
              <div className="w-full p-6 lg:py-8 lg:px-10">
                <div className="mb-3">
                  <div className="font-semibold inline-flex px-2 py-1 leading-4 text-sm rounded-full text-blue-700 bg-blue-200">
                    {tag}
                  </div>
                </div>
                <h4 className="font-bold text-lg sm:text-xl mb-3 leading-7 text-gray-800 hover:text-gray-600">
                  {title}
                </h4>
                <p className="prose prose-blue">{description}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default Articles;
