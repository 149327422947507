import React from "react";
import { useBreakpoints } from "../../../hooks";

const About = () => {
  const bp = useBreakpoints();
  const currentDate = new Date();
  const yearsOfExperience = currentDate.getFullYear() - 2015;
  return (
    <div className="bg-blue-500" id="about">
      <div className="container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32 space-y-16">
        <div className="text-center">
          <p className="text-3xl md:text-4xl font-extrabold mb-4 text-white">
            👋 Hi, I'm Daniel.
            {bp === "" ? <br /> : " "}
            It's nice to meet you.
          </p>
          <p className="text-base md:leading-relaxed font-medium text-white ml-0 md:mx-auto md:w-4/5 lg:w-3/4 mx-auto">
            I began my software development journey {yearsOfExperience} years
            ago, working for government, media, financial, and e-commerce companies. I enjoy solving complex
            problems and creating impactful products that people love using. I
            am humbly confident, collaborative, and curious; I am constantly
            learning and keeping up to date with the latest technologies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
